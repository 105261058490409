import {MajorSyncErrorType, MinorSyncErrorType} from "../models/error";
import {
  ErrorEventPayload,
  ReportEventPayload,
  ReportHint,
  ResultReadyEventPayload,
  StageCompleteEventPayload,
} from "../models/event";
import {SyncStage} from "../models/message";
import {store} from "../store";
import {setError} from "../store/syncSessionSlice";

export function handleStageCompleteEvent(payload: StageCompleteEventPayload, sessionId: string) {
  const {stage, error} = payload;
  // send user back to login page if authentication failed. Save error message for display
  switch (stage[stage.length - 1]) {
    case SyncStage.Authenticate: {
      if (error) {
        store.dispatch(setError(error));
        return true;
      }
      break;
    }
  }
  return false;
}

export function handleErrorEvent(payload: ErrorEventPayload, sessionId: string) {
  const {error} = payload;
  if (
    // Prompt-related error
    error.majorType === MajorSyncErrorType.User &&
    error.minorType === MinorSyncErrorType.InvalidMFA
  ) {
    store.dispatch(setError(error));
  }
}

export function handleReportEvent(payload: ReportEventPayload): boolean {
  const {hint} = payload;
  if (hint != null) {
    switch (hint) {
      case ReportHint.NoMoreInteractiveSteps:
        return true;
    }
  }
  return false;
}

export function handleResultReadyEvent(payload: ResultReadyEventPayload): boolean {
  // no-op for parity
  return true;
}
