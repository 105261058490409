import {PlusIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";

import {Button} from "../components/Buttons/Button";
import GoBackButton from "../components/Buttons/GoBackButton";
import ConnectedServiceLine from "../components/ConnectedServiceLine";
import ContentHeader from "../components/ContentHeader";
import {useMobileHeaderBar} from "../contexts/MobileHeaderBarContext";
import useStateNavigate from "../hooks/useStateNavigate";
import {getOAuthParams} from "../store/authSlice";
import {getClientConfig} from "../store/clientConfigSlice";
import {useAppSelector} from "../store/hooks";
import {getUserParams} from "../store/userSlice";
import {handleBackToClient} from "../utils";
import AuthenticatedPageTemplate from "./template/AuthenticatedPageTemplate";
import ContentBody from "./template/ContentBody";
import {ContentFooter} from "./template/ContentFooter";

export default function ConnectedServiceLines() {
  const {navigateBack, navigateNext} = useStateNavigate();
  const auth = useAppSelector(getOAuthParams);
  const {customerShortName} = useAppSelector(getClientConfig);
  const {profile} = useAppSelector(getUserParams);
  const {setGoBackControl} = useMobileHeaderBar();
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  async function onSubmit(): Promise<void> {
    setIsFormLoading(true);
    await handleBackToClient(auth.redirectUri, auth.state);
  }

  const text = profile.authorized
    ? `The following accounts have already been connected for ${profile.company.name}. Return to ${customerShortName}, or add another account.`
    : `The following accounts have already been connected for ${profile.company.name}. Authorize ${customerShortName} to access data from these accounts, or add another account.`;

  useEffect(() => {
    setGoBackControl({showGoBack: true, goBackCallback: navigateBack});
  }, [navigateBack, setGoBackControl]);

  return (
    <AuthenticatedPageTemplate>
      <ContentBody>
        <ContentHeader title="Connected accounts" />
        <div className="mt-4 md:mt-10">
          <p>{text}</p>
        </div>
        <div className="flex flex-col gap-y-2 md:gap-y-4 mt-6 md:mt-10 mb-6">
          {profile.serviceLines.map((sl, i) => (
            <ConnectedServiceLine key={i} serviceLine={sl} />
          ))}
        </div>
      </ContentBody>
      <ContentFooter>
        <div className="flex flex-col gap-y-2 md:gap-y-4 w-full">
          <Button
            primary
            fullWidth
            label={profile.authorized ? `Return to ${customerShortName}` : `Authorize access for ${customerShortName}`}
            loading={isFormLoading}
            disabled={isFormLoading}
            onClick={() => void onSubmit()}
          />
          <Button
            label="Add another account"
            icon={<PlusIcon className="h-6 w-6" />}
            fullWidth
            onClick={() => navigateNext("/platforms")}
          />
        </div>
        <div className="flex w-full max-md:hidden">
          <GoBackButton onClick={() => navigateBack()} />
        </div>
      </ContentFooter>
    </AuthenticatedPageTemplate>
  );
}
