import ExclamationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import React, {ReactElement} from "react";

import ContentBody from "../../pages/template/ContentBody";
import PageTemplate from "../../pages/template/PageTemplate";
import {getHasInitialized} from "../../store/authSlice";
import {useAppSelector} from "../../store/hooks";
import ContentHeader from "../ContentHeader";
import DesktopMinimalSidebar from "../Layout/DesktopMinimalSidebar";
import HeaderBar from "../Layout/HeaderBar";

interface GlobalErrorScreenProps {
  titleText: string;
  body: ReactElement;
}

export default function GlobalErrorScreen({titleText, body}: GlobalErrorScreenProps): ReactElement {
  const hasInitialized = useAppSelector(getHasInitialized);

  return (
    <div className="flex flex-col h-full w-full">
      {
        // Cannot display unless clientConfig is available in store
        hasInitialized && (
          <div className="flex-none w-full">
            <HeaderBar />
          </div>
        )
      }
      <div className="grow w-full min-h-0 h-full">
        <div className="flex w-full h-full">
          <div className="max-md:hidden relative flex w-auto">
            <DesktopMinimalSidebar shouldDisplayLogo={false} />
          </div>
          <div className="flex flex-col h-full w-full items-center">
            <PageTemplate>
              <ContentBody>
                <div className="flex flex-row justify-start w-full mb-8">
                  <ExclamationCircleIcon className="h-10 w-10 mr-4 fill-red-500 stroke-white" />
                  <ContentHeader title={titleText} />
                </div>
                <div className="ml-2">{body}</div>
              </ContentBody>
            </PageTemplate>
          </div>
        </div>
      </div>
    </div>
  );
}
