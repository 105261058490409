import {SubuserStrategy} from "./components/OngoingConnectivity/OngoingConnectivityCommon";
import {SyncError} from "./models/error";
import {Prompt} from "./models/prompt";

export interface AuthState {
  oauthParams: OAuthParams;
  initTokenParams: InitToken;
  hasInitialized: boolean;
  authenticationStatus: AuthenticationStatus;
}

export interface OAuthParams {
  clientId: string;
  scope: string;
  redirectUri: string;
  state: string;
  codeChallenge: string;
  initToken: string;
}

export interface AuthenticationStatus {
  emailAuthenticated: boolean;
  emailChallenged: boolean | null;
}

export interface InitToken {
  clientId: string;
  companyEin: string | null;
  companyName: string;
  exp: string;
  iat: string;
  platformId: string | null;
  nativeCompanyId: string | null;
  nativeUserId: string | null;
  userEmail: string;
  userName: string | null;
  userPhone: string | null;
}

export interface Profile {
  company: Company;
  user: User;
  serviceLines: Array<ServiceLine>;
  verified: boolean;
  authorized: boolean;
}

export interface User {
  emailAddress: string;
  name: string | null;
}

export interface Company {
  ein: string | null;
  name: string;
}

export type MarkdownType = string;

export enum SyncAuthorizationSource {
  Basis = "basis",
  BasisManual = "basis-manual",
  User = "user",
}

export interface BasicPlatform {
  logo: string | null;
  icon: string | null;
  name: string;
  subuserStrategy: SubuserStrategy;
}

export interface Account {
  displayName: string;
  accountMask: string | null;
}

export interface SyncAuthorization {
  slug: string;
  source: SyncAuthorizationSource;
  createTime: string;
}

export interface ServiceLine {
  slug: string;
  platform: BasicPlatform;
  accounts: Array<Account>;
}

export interface SyncResult {
  syncTargetId: string;
  subuserAuthorization: SyncAuthorization | null;
  platform: BasicPlatform;
  accounts: Array<Account>;
  syncTargetParameters: {
    createUser: boolean;
  };
}

export interface ClientConfig {
  clientId: string;
  customerShortName: string;
  customerFullName: string;
  customerLogo: string;
  trustEmail: boolean;
  landHeader: string | null;
  landSubtitle: string | null;
  landBody: MarkdownType | null;
  landServiceInfo: string | null;
  landCTA: string | null;
  summaryTitle: string | null;
  summarySubtitle: string | null;
  summaryBody: string | null;
  summaryCTA: string | null;
  defaultPlatformSlugs: string[] | null;
}

export interface SyncSessionParams {
  sessionId: string;
  syncTargetId: string;
  platformId: string;
  platformConfig: Platform;
  // in case of a user error, or recoverable system error, this will inform ui guidance for the user.
  error: null | SyncError;
  // in event of a catastrophic error (such as ws disconnect), this will inform the sync error screen what went wrong.
  terminalError: null | SyncError;
}

export enum PlatformAggregatorType {
  Finicity = "finicity",
}

interface PlatformAggregatorMeta {
  aggregatorType: PlatformAggregatorType;
  institutionId?: number;
}

export interface Platform {
  platformId: string;
  displayName: string;
  platformType: string;
  homepageUrl: string;
  logo: string | null;
  icon: string | null;
  aggregator: PlatformAggregatorMeta | null;
  credentialPrompts: Prompt[];
  subuserStrategy: SubuserStrategy;
  aggregatorUrl: string | null;
  aggregatorUsername: string | null;
}

export interface Resource {
  resourceId: string;
  promptId: string;
  mimeType: string;
  name: string;
}

export interface Progress {
  current: number;
  total: number;
}

export interface FileResource extends Resource {
  data?: File;
  fileId: string;
}

export interface FileTransferItem {
  file?: FileResource;
  progress?: Progress;
  isUploading: boolean;
  error?: string;
}
