import "./index.css";

import * as Sentry from "@sentry/react";
import {AnimatePresence} from "framer-motion";
import {ReactElement, Suspense, useEffect} from "react";
import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import AppErrorFallback from "./components/Exception/AppErrorFallback";
import NotFoundFallback from "./components/Exception/NotFoundFallback";
import AnimatedDesktopSidebar from "./components/Layout/AnimatedDesktopSidebar";
import HeaderBar from "./components/Layout/HeaderBar";
import {LoadingSpinner} from "./components/Suspense/Loading";
import LoadingSpinnerPadder from "./components/Suspense/LoadingSpinnerPadder";
import {ENTRY_PATH} from "./constants/constants";
import {AnimationStateProvider} from "./contexts/AnimationStateContext";
import {MobileHeaderBarProvider} from "./contexts/MobileHeaderBarContext";
import AnimatedPageOutlet from "./pages/AnimatedPageOutlet";
import Auth from "./pages/Auth";
import ConnectedServiceLines from "./pages/ConnectedServiceLines";
import InstitutionSearch from "./pages/InstitutionSearch";
import LandingPage from "./pages/Land";
import Platform from "./pages/Platform";
import Summary from "./pages/Summary";
import Sync from "./pages/Sync";
import PageTemplate from "./pages/template/PageTemplate";
import VerifyEmail from "./pages/VerifyEmail";
import reportWebVitals from "./reportWebVitals";
import {store} from "./store";

Sentry.init({
  enabled: process.env.REACT_APP_HOST_ENV !== "local",
  // https://basis-lf.sentry.io/projects/connect-ui/?project=4506396583460864
  dsn: "https://9499b4e9668ea238251574a27e3f24ec@o4506384299327488.ingest.sentry.io/4506396583460864",
  environment: process.env.REACT_APP_HOST_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      mask: [".replay-redact"],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/\//, /.*usebasis\.co.*/],

  // Capture Replay for 100% of all sessions, plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root") as Element;
const root = createRoot(container);

function BasisConnectApp(): ReactElement {
  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex-none w-full">
        <HeaderBar />
      </div>
      <div className="grow w-full min-h-0">
        <div className="flex w-full h-full">
          <div className="max-md:hidden relative flex h-full w-auto">
            <AnimatePresence mode="wait">
              <AnimatedDesktopSidebar />
            </AnimatePresence>
          </div>
          <div className="grow overflow-y-auto">
            <AnimationStateProvider>
              <AnimatePresence mode="wait">
                <AnimatedPageOutlet />
              </AnimatePresence>
            </AnimationStateProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
  {
    element: (
      <MobileHeaderBarProvider>
        <Sentry.ErrorBoundary fallback={<AppErrorFallback />}>
          <Suspense
            fallback={
              <PageTemplate>
                <LoadingSpinnerPadder>
                  <LoadingSpinner />
                </LoadingSpinnerPadder>
              </PageTemplate>
            }>
            <BasisConnectApp />
          </Suspense>
        </Sentry.ErrorBoundary>
      </MobileHeaderBarProvider>
    ),
    children: [
      {
        path: ENTRY_PATH,
        element: <LandingPage />,
      },
      {
        path: "auth",
        element: <Auth />,
      },
      {
        path: "verify",
        element: <VerifyEmail />,
      },
      {
        path: "connected",
        element: <ConnectedServiceLines />,
      },
      {
        path: "sync",
        element: <Sync />,
      },
      {
        path: "platforms/:platformId",
        element: <Platform />,
      },
      {
        path: "platforms",
        element: <InstitutionSearch />,
      },
      {
        path: "summary",
        element: <Summary />,
      },
    ],
  },
  {
    path: "*",
    //  TODO: 2024-09-24 - BAS-1397 - Refactor MHBP to BackButtonProvider and move above router, or restructure app
    element: (
      <MobileHeaderBarProvider>
        <NotFoundFallback />
      </MobileHeaderBarProvider>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <div className="flex flex-col h-[100dvh] w-screen antialiased items-center">
        <RouterProvider router={router} />
      </div>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
