import {useCallback} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {ENTRY_PATH} from "../constants/constants";
import {getAuthenticationParams} from "../store/authSlice";
import {getInitTokenParams} from "../store/authSlice";
import {getClientConfig} from "../store/clientConfigSlice";
import {useAppSelector} from "../store/hooks";
import {getUserParams} from "../store/userSlice";

const useStateNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authParams = useAppSelector(getAuthenticationParams);
  const initTokenParams = useAppSelector(getInitTokenParams);
  const {authFieldGaps, profile} = useAppSelector(getUserParams);

  const clientConfig = useAppSelector(getClientConfig);

  const navigateNext = useCallback(
    (path?: string, replace = false) => {
      if (path) {
        navigate(path, {replace});
        return;
      }

      const hasMissingFields = authFieldGaps.missingFields.length > 0;
      const isEmailTrusted = clientConfig.trustEmail;
      const isEmailAuthenticated = authParams.emailAuthenticated;
      const hasServiceLines = profile.serviceLines.length > 0;

      const navigateToAuth = (replace = false) => navigate("/auth", {replace});
      const navigateToVerify = (replace = false) => navigate("/verify", {replace});
      const navigateToConnected = (replace = false) => navigate("/connected", {replace});
      const navigateToPlatformSelection = (replace = false) => navigate("/platforms", {replace});
      const navigateToPlatform = (platformId: string, replace = false) => {
        navigate(`/platforms/${platformId}`, {replace});
      };

      // Note: /platforms, /platforms/:platformId, and /sync manage their own navigation
      switch (location.pathname) {
        case ENTRY_PATH:
          if (hasMissingFields) {
            navigateToAuth();
            return;
          }

          if (isEmailTrusted || isEmailAuthenticated) {
            if (hasServiceLines) {
              navigateToConnected();
              return;
            }

            if (initTokenParams.platformId) {
              navigateToPlatform(initTokenParams.platformId);
              return;
            }

            navigateToPlatformSelection();
            return;
          }

          navigateToVerify();
          return;

        case "/auth":
          if (isEmailTrusted) {
            if (hasServiceLines) {
              navigateToConnected(true);
              return;
            }

            if (initTokenParams.platformId) {
              navigateToPlatform(initTokenParams.platformId, true);
              return;
            }

            navigateToPlatformSelection(true);
            return;
          }

          navigateToVerify(true);
          return;

        case "/verify":
          if (isEmailAuthenticated) {
            if (hasServiceLines) {
              navigateToConnected(true);
              return;
            }

            if (initTokenParams.platformId) {
              navigateToPlatform(initTokenParams.platformId, true);
              return;
            }

            navigateToPlatformSelection(true);
            return;
          }

          throw new Error("Attempted to continue without verifying.");
          return;

        case "/connected":
          if (initTokenParams.platformId) {
            navigateToPlatform(initTokenParams.platformId);
            return;
          }

          navigateToPlatformSelection();
          return;

        case "/summary":
          navigateToPlatformSelection();
          return;

        default:
          throw new Error(`Unhandled route: ${location.pathname}`);
      }
    },
    [
      authFieldGaps.missingFields.length,
      authParams.emailAuthenticated,
      clientConfig.trustEmail,
      initTokenParams.platformId,
      location.pathname,
      navigate,
      profile.serviceLines.length,
    ],
  );

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    navigateNext,
    navigateBack,
  };
};

export default useStateNavigation;
