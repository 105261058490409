import {ErrorBoundary} from "@sentry/react";
import React, {ReactElement, Suspense, useState} from "react";

import {Button} from "../components/Buttons/Button";
import PlusButton from "../components/Buttons/PlusButton";
import ContentHeader from "../components/ContentHeader";
import {SyncResultSummaries, SyncResultSummarySuspense} from "../components/Results/SyncResultSummary";
import {useCopy} from "../hooks/useCopy";
import useStateNavigation from "../hooks/useStateNavigate";
import {getOAuthParams} from "../store/authSlice";
import {useAppSelector} from "../store/hooks";
import {handleBackToClient} from "../utils";
import AuthenticatedPageTemplate from "./template/AuthenticatedPageTemplate";
import ContentBody from "./template/ContentBody";
import {ContentFooter} from "./template/ContentFooter";

export default function Summary(): ReactElement {
  const {navigateNext} = useStateNavigation();
  const auth = useAppSelector(getOAuthParams);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const {summaryTitle, summaryCTA} = useCopy();

  async function onSubmit(): Promise<void> {
    setIsFormLoading(true);
    await handleBackToClient(auth.redirectUri, auth.state);
  }

  return (
    <AuthenticatedPageTemplate>
      <ContentBody>
        <ContentHeader title={summaryTitle} />
        <div className="flex mt-6 md:mt-10 w-full">
          <ErrorBoundary>
            <Suspense fallback={<SyncResultSummarySuspense />}>
              <SyncResultSummaries />
            </Suspense>
          </ErrorBoundary>
        </div>
        <div className="mt-4 md:mt-8">
          <PlusButton
            onClick={() => {
              navigateNext("/platforms");
            }}>
            <span>Add another account</span>
          </PlusButton>
        </div>
      </ContentBody>
      <ContentFooter>
        <Button
          primary
          fullWidth
          label={summaryCTA}
          loading={isFormLoading}
          disabled={isFormLoading}
          onClick={() => void onSubmit()}
        />
      </ContentFooter>
    </AuthenticatedPageTemplate>
  );
}
