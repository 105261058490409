import {useRef} from "react";
import {useSearchParams} from "react-router-dom";
import {suspend} from "suspend-react";

import {initialize} from "../api/connectApi";
import {OAuthParams, Profile} from "../interfaces";
import {getOAuthParams, setHasInitialized, setOAuthParams} from "../store/authSlice";
import {setClientConfig} from "../store/clientConfigSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {setMissingFields, setProfile} from "../store/userSlice";
import {getInitTokenMissingRequiredFields, parseInitToken, redirectToClient} from "../utils";

export default function useSession(): void {
  const dispatch = useAppDispatch();
  const [queryParams] = useSearchParams();
  const initializedRef = useRef<boolean>(false);
  const sessionParams = useAppSelector(getOAuthParams);
  let oauthParams: OAuthParams;

  if (initializedRef.current) return;

  const requiredParams = ["client_id", "scope", "redirect_uri", "state", "code_challenge", "init_token"];
  const params = Object.fromEntries(requiredParams.map((param) => [param, queryParams.get(param)]));

  // TODO 2024-09-24: Temporary solution to handle existing session state.
  // Proper solution should request session from API
  if (Object.values(params).some((value) => value === null) && !sessionParams.initToken) {
    // validate query params
    throw new Error("Missing required query params");
  } else if (Object.values(params).every((value) => value != null)) {
    // use query params
    oauthParams = {
      clientId: params.client_id ?? "",
      scope: params.scope ?? "",
      redirectUri: params.redirect_uri ?? "",
      state: params.state ?? "",
      codeChallenge: params.code_challenge ?? "",
      initToken: params.init_token ?? "",
    };
  } else {
    // otherwise, use existing session params
    oauthParams = sessionParams;
  }
  dispatch(setOAuthParams(oauthParams));

  const initTokenParams = parseInitToken(oauthParams.initToken);
  const missingFields = getInitTokenMissingRequiredFields(initTokenParams);
  dispatch(setMissingFields(missingFields));

  initializedRef.current = true;

  suspend(async () => {
    const resp = await initialize({...oauthParams});
    if (resp?.result.success) {
      initializedRef.current = true;
      dispatch(setHasInitialized(true));
      const {clientConfig, location} = resp.payload;
      if (location != null) {
        redirectToClient(location);
      }
      if (clientConfig != null) {
        dispatch(setClientConfig(clientConfig));
      }
      const profile: Profile = {
        company: {
          ein: initTokenParams.companyEin,
          name: initTokenParams.companyName,
        },
        user: {
          emailAddress: initTokenParams.userEmail,
          name: initTokenParams.userName,
        },
        serviceLines: [],
        verified: false,
        authorized: false,
      };
      dispatch(setProfile(profile));
    }
  }, []);
}
