import {useMemo} from "react";

import {getClientConfig} from "../store/clientConfigSlice";
import {useAppSelector} from "../store/hooks";

export function useCopy() {
  const {
    landHeader,
    landSubtitle,
    landBody,
    landServiceInfo,
    landCTA,
    summaryTitle,
    summarySubtitle,
    summaryBody,
    summaryCTA,
    customerShortName,
  } = useAppSelector(getClientConfig);

  return useMemo(() => {
    const defaults = {
      landHeader: "Connect your account",
      landSubtitle: `${customerShortName} partners with Basis for secure financial data sharing.`,
      landBody: `Basis will help you build a financial profile of your business so that ${customerShortName} can 
                         serve you.`,
      landServiceInfo:
        "- Basic information about your business is used to create a profile and verify your identity.\n" +
        "- Basis assists you in connecting your business's bank accounts to your profile.\n" +
        `- ${customerShortName} uses this information to serve you.`,
      landCTA: "Continue",
      summaryTitle: "Summary",
      summarySubtitle: "What's next",
      summaryCTA: `Authorize Access for ${customerShortName}`,
      summaryRetryCTA: `Finish and return to ${customerShortName}`,
    };

    return {
      landHeader: landHeader ?? defaults.landHeader,
      landSubtitle: landSubtitle ?? defaults.landSubtitle,
      landBody: landBody ?? defaults.landBody,
      landServiceInfo: landServiceInfo ?? defaults.landServiceInfo,
      landCTA: landCTA ?? defaults.landCTA,
      slideupSubtitle: defaults.landSubtitle,
      summaryTitle: summaryTitle ?? defaults.summaryTitle,
      summarySubtitle: summarySubtitle ?? defaults.summarySubtitle, // TODO: 2024-08-20 - BAS-1442 remove summaryBody from client configurable copy
      summaryBody: summaryBody ?? null, // TODO: 2024-08-20 - BAS-1442 remove summaryBody from client configurable copy
      summaryCTA: summaryCTA ?? defaults.summaryCTA,
      summaryRetryCTA: summaryCTA ?? defaults.summaryRetryCTA,
    };
  }, [
    customerShortName,
    landBody,
    landCTA,
    landHeader,
    landServiceInfo,
    landSubtitle,
    summaryBody,
    summaryCTA,
    summarySubtitle,
    summaryTitle,
  ]);
}
