import {
  Connect,
  ConnectCancelEvent,
  ConnectDoneEvent,
  ConnectErrorEvent,
  ConnectEventHandlers,
  ConnectOptions,
  ConnectRouteEvent,
} from "connect-web-sdk";
import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {suspend} from "suspend-react";

import {RequestError} from "../api/apiHandler";
import {PlatformResponse, getPlatform, sync} from "../api/connectApi";
import {ReactComponent as LockIconSVG} from "../assets/lock_icon.svg";
import {Button} from "../components/Buttons/Button";
import GoBackButton from "../components/Buttons/GoBackButton";
import ContentHeader from "../components/ContentHeader";
import {FormTextField} from "../components/FormTextField";
import {PaddedPlatformLogo} from "../components/PlatformLogo";
import PromptErrorMessage from "../components/SyncMessages/PromptErrorMessage";
import {useMobileHeaderBar} from "../contexts/MobileHeaderBarContext";
import useAsyncError from "../hooks/useAsyncError";
import useStateNavigate from "../hooks/useStateNavigate";
import {Platform, PlatformAggregatorType} from "../interfaces";
import {MajorSyncErrorType, MinorSyncErrorType, SyncError} from "../models/error";
import {Prompt, PromptType} from "../models/prompt";
import {getInitTokenParams} from "../store/authSlice";
import {getClientConfig} from "../store/clientConfigSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {createSyncSession, getError} from "../store/syncSessionSlice";
import {parseFormData} from "../utils";
import {AuthenticatedPage} from "./template/AuthenticatedPageTemplate";
import ContentBody from "./template/ContentBody";
import {ContentFooter} from "./template/ContentFooter";
import PageTemplate from "./template/PageTemplate";

interface ConnectUserEvent {
  action?: string;
  institutionLoginId?: number;
  [key: string]: unknown;
}

export default function PlatformView(): ReactElement {
  const asyncThrow = useAsyncError();
  const {navigateNext, navigateBack} = useStateNavigate();
  const initTokenParams = useAppSelector(getInitTokenParams);
  const {platformId: pathPlatformId} = useParams<{platformId: string}>();
  const platformId = pathPlatformId ?? initTokenParams?.platformId;
  const dispatch = useAppDispatch();
  const clientConfig = useAppSelector(getClientConfig);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const institutionLoginIdRef = useRef<number>();
  const debug = process.env.REACT_APP_HOST_ENV === "local";
  const {setGoBackControl} = useMobileHeaderBar();
  const [formError, setFormError] = useState<SyncError | null>(useAppSelector(getError));

  const platform = useMemo((): Platform | null => {
    if (!platformId) return null;
    const resp = suspend(getPlatform(platformId), [platformId]) as PlatformResponse;
    if (!resp.result.success) throw new Error("Failed to fetch platform");
    return resp.payload;
  }, [platformId]);

  function onInput(ev: React.FormEvent<HTMLFormElement>): void {
    setIsFormValid(ev.currentTarget.checkValidity());
  }

  async function onSubmit(ev: React.FormEvent<HTMLFormElement>): Promise<void> {
    ev.preventDefault();
    setFormError(null);
    setIsFormLoading(true);
    const formData = new FormData(ev.currentTarget);
    const credentials = parseFormData(formData);
    await startSyncSession(credentials);
    setIsFormLoading(false);
  }

  const startSyncSession = useCallback(
    async (credentials: {[key: string]: string | number}) => {
      if (!platform) return null;
      try {
        const resp = await sync({platformId: platform.platformId, credentials});

        if (resp.result.success) {
          dispatch(
            createSyncSession({
              ...resp.payload,
              platformConfig: platform,
              error: null,
              terminalError: null,
            }),
          );

          navigateNext("/sync");
        }
      } catch (e) {
        if (e instanceof RequestError) {
          setFormError({
            majorType: MajorSyncErrorType.Internal,
            minorType: MinorSyncErrorType.Unknown,
            reason: "",
          });
        } else {
          asyncThrow(e);
        }
      }
    },
    [asyncThrow, dispatch, navigateNext, platform],
  );

  const openFinicityConnect = useCallback(() => {
    const opts: ConnectOptions = {
      overlay: "rgba(199,201,199, 0.5)",
      selector: "#aggregator-connect",
    };

    const evHandlers: ConnectEventHandlers = {
      onDone: (ev: ConnectDoneEvent) => {
        if (debug) console.log("onDone", ev);
        if (!platform) return null;
        if (ev.code === 200 && platform.aggregatorUsername && institutionLoginIdRef.current) {
          const creds = {
            customer_username: platform.aggregatorUsername,
            institution_login_id: institutionLoginIdRef.current,
          };
          void startSyncSession(creds);
        }
      },
      onCancel: (ev: ConnectCancelEvent) => {
        if (debug) console.log("onCancel", ev);
        navigateBack();
      },
      onError: (ev: ConnectErrorEvent) => {
        if (debug) console.log("onError", ev);
      },
      onRoute: (ev: ConnectRouteEvent) => {
        if (debug) console.log("onRoute", ev);
      },
      onUser: (ev: ConnectUserEvent) => {
        if (debug) console.log("onUser", ev);
        if ((ev.action === "AddAccounts" || ev.action === "OAuthAddAccounts") && ev.institutionLoginId) {
          institutionLoginIdRef.current = ev.institutionLoginId;
        }
      },
      onLoad: () => {
        if (debug) console.log("loaded");
      },
    };

    try {
      if (platform?.aggregatorUrl) Connect.launch(platform.aggregatorUrl, evHandlers, opts);
    } catch (e) {
      asyncThrow(e);
    }
  }, [debug, platform, startSyncSession, navigateBack, asyncThrow]);

  useEffect(() => {
    if (platform?.aggregator?.aggregatorType === PlatformAggregatorType.Finicity && !institutionLoginIdRef.current)
      void openFinicityConnect();
    return () => {
      Connect.destroy();
    };
  }, [platform, openFinicityConnect]);

  const formRef = useRef<HTMLFormElement>(null);

  const platformIsAggregator = platform?.aggregator;

  useEffect(() => {
    if (!platformIsAggregator) {
      setGoBackControl({showGoBack: true, goBackCallback: navigateBack});
    }
  }, [navigateBack, platformIsAggregator, setGoBackControl]);

  return (
    <AuthenticatedPage>
      <div
        id="aggregator-connect"
        className={
          platformIsAggregator
            ? "relative w-full h-full flex flex-col max-w-2xl mx-auto max-h-[800px] md:mt-24"
            : "hidden"
        }></div>
      <div className={!platformIsAggregator ? "flex flex-col w-full h-full items-center" : "hidden"}>
        <PageTemplate>
          <ContentBody>
            <ContentHeader title="Connect to your bank" />
            <div className="mt-4 md:mt-10">
              <p className="text-md md:text-lg">
                Please enter your details below. This authorizes Basis to gather your data on
                {` ${clientConfig.customerShortName}'s`} behalf.
              </p>
            </div>
            {platform && <PaddedPlatformLogo logo={platform.logo} displayName={platform.displayName} />}
            <form
              className="replay-redact flex flex-col mt-2 md:mt-8 md:mb-4 gap-y-6 md:gap-y-8"
              ref={formRef}
              onInput={(ev) => void onInput(ev)}
              onSubmit={(ev) => void onSubmit(ev)}>
              {platform?.credentialPrompts.map((prompt: Prompt) => {
                if (prompt.promptType === PromptType.Text) {
                  return (
                    <FormTextField
                      key={prompt.promptId}
                      name={prompt.promptId}
                      pattern="^.+$"
                      label={prompt.label}
                      defaultValue={prompt.defaultResponse || ""}
                      readOnly={prompt.defaultResponse !== null}
                      type="text"
                      required
                      fullWidth
                    />
                  );
                } else if (prompt.promptType === PromptType.Numeric) {
                  return (
                    <FormTextField
                      key={prompt.promptId}
                      name={prompt.promptId}
                      label={prompt.label}
                      defaultValue={prompt.defaultResponse || ""}
                      readOnly={prompt.defaultResponse !== null}
                      type="number"
                      required
                      fullWidth
                    />
                  );
                } else if (prompt.promptType === PromptType.Password) {
                  return (
                    <FormTextField
                      key={prompt.promptId}
                      name={prompt.promptId}
                      label={prompt.label}
                      type="password"
                      required
                      fullWidth
                      autoComplete="current-password"
                    />
                  );
                } else {
                  return null;
                }
              })}
              <button type="submit" className="hidden" />
            </form>
          </ContentBody>
          <ContentFooter>
            <Button
              label="Continue"
              primary
              fullWidth
              disabled={!isFormValid || isFormLoading}
              loading={isFormLoading}
              icon={<LockIconSVG className={`${!isFormValid || isFormLoading ? "stroke-navy-200" : ""}`} />}
              onClick={() => formRef?.current?.requestSubmit()}
            />
            {formError && <PromptErrorMessage error={formError} />}
            <div className="max-md:hidden w-full">
              <GoBackButton onClick={() => navigateBack()} />
            </div>
          </ContentFooter>
        </PageTemplate>
      </div>
    </AuthenticatedPage>
  );
}
