import React, {ReactElement} from "react";

import {getClientConfig} from "../../store/clientConfigSlice";
import {useAppSelector} from "../../store/hooks";
import GlobalErrorScreen from "./GlobalErrorScreen";

export default function AppErrorFallback(): ReactElement {
  const {customerShortName} = useAppSelector(getClientConfig);
  const customerKnown = customerShortName !== "";
  const guidanceText = customerKnown
    ? `If you are attempting to connect your accounts to ${customerShortName}, please return to their website and try again.`
    : "Please try again.";

  return (
    <GlobalErrorScreen
      titleText="Something went wrong."
      body={
        <div className="flex flex-col gap-y-8">
          <div className="text-xl">An unexpected error occurred.</div>
          <div>{guidanceText}</div>
        </div>
      }
    />
  );
}
